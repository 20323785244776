import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosHelper from "../helpers/AxiosHelper";
import logo from "../assets/LOGO_TECH_MEDIO.png";

const ClientRedirector: React.FC = () => {
  const params = useParams();
  const [ciccio, setCiccio] = useState("ciao");

  const changePath = async () => {
    console.log(params.id);
    if (params.id)
      await axiosHelper
        .getClientUrl(params.id)
        .then((response) => {
          console.log(response.link);
          setCiccio(response.link);
          // });
          window.location.replace(response.link);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    changePath();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <img src={logo} style={{maxWidth:'300px'}}/>
        </div>
      </div>
    </>
  );
};

export default ClientRedirector;
