import axios from "axios";

class axiosHelper {
  constructor() {
    console.log("constructor di axioshelper");
  }

  getClientUrl(idClient: string) {
    return axios
      .get(`${process.env.REACT_APP_ENDPOINT}/client/${idClient}`)
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
export default new axiosHelper();
