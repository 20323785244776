import { Route, Routes } from "react-router-dom";
import { ROUTEPATHS } from "./routes/routePaths";
import ClientRedirector from "./routes/ClientRedirector";

const Container = () => {
  return (
    <Routes>
      <Route path={`${ROUTEPATHS.CLIENT}/:id`} element={<ClientRedirector  />} />
    </Routes>
  );
};
export default Container;
