import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Container from "./Container";

const App: React.FC = () => {
  useEffect(() => {
    console.log('charged app')
  }, []);

  return (
    <>
      <Router>
        <Container />
      </Router>
    </>
  );
};

export default App;
